<!-- footer -->
<footer [ngClass]="class">
    <div class="white-layout" *ngIf="mainFooter">
        <section class="p-0">
            <div class="container-fluid">
                <div class="row footer-theme2 section-light footer-border">
                    <div class="col">
                        <div class="footer-block">
                            <div class="footer-container">
                                <div class="footer-title footer-mobile-title">
                                    <h4>about</h4>
                                </div>
                                <div class="footer-contant">
                                    <div class="footer-logo">
                                        <img [src]="themeLogo" alt="">
                                    </div>
                                    <div class="social-white">
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/people/Aquila-Tech-Solutions-Pvt-Ltd/61554873970962/?mibextid=LQQJ4d&rdid=3BjiDBr5U6aC8HRl&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FtPtZST5MakdApPsU%2F%3Fmibextid%3DLQQJ4d" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                            </li>
                                            <!-- <li>
                                                <a href="javascript:void(0)" target="_blank"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/aquilatechsolutionspvtltd/" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                            </li> -->
                                            <li>
                                                <a href="https://www.instagram.com/aquilatechsolutionspvtltd/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                            </li>
                                            <!-- <li>
                                                <a href="javascript:void(0)" target="_blank"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                            </li> -->
                                            <li>
                                                <a href="https://www.linkedin.com/company/aquila-tech-solutions-pvt-ltd" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                            </li>

                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col form-p y-0">
                        <div class="footer-block">
                            <div class="subscribe-white">
                                <h2>newsletter</h2>
                                <form>
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="exampleFormControlInput"
                                            placeholder="Enter your email">
                                        <button type="submit" class="btn btn-solid black-btn">subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="footer-block">
                            <div class="footer-container">
                                <div class="footer-title">
                                    <h4>Get In Touch</h4>
                                </div>
                                <div class="footer-contant">
                                    <ul class="contact-details">
										<li><i class="fa fa-map-marker"></i>&nbsp; Aquila Tech Solutions Pvt Ltd <br/>
                                            B3 -226-227, SPAZE &nbsp; iTech Park, sec 49 ,<br/> Gurugram - 122018</li>
										<li><i class="fa fa-phone"></i>&nbsp; (+91) 124-4240053 </li>
										<li><i class="fa fa-envelope-o"></i><a> &nbsp;contact&#64;aquilatechsol.com</a></li>
										<li><i class="fa fa-time"></i> Mon - Fri 10:00 AM - 07:00 PM</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="white-layout box-layout" *ngIf="subFooter">
        <div class="container">
            <section class="small-section">
                <div class="row footer-theme2">
                    <div class="col">
                        <div class="footer-link link-white">
                            <div class="footer-title">
                                <h4>my account</h4>
                            </div>
                            <div class="footer-contant">
                                <ul>
                                    <li><a href="javascript:void(0)">mens</a></li>
                                    <li><a href="javascript:void(0)">womens</a></li>
                                    <li><a href="javascript:void(0)">clothing</a></li>
                                    <li><a href="javascript:void(0)">accessories</a></li>
                                    <li><a href="javascript:void(0)">featured</a></li>
                                    <li><a href="javascript:void(0)">service</a></li>
                                    <li><a href="javascript:void(0)">cart</a></li>
                                    <li><a href="javascript:void(0)">my order</a></li>
                                    <li><a href="javascript:void(0)">FAQ</a></li>
                                    <li><a href="javascript:void(0)">new product</a></li>
                                    <li><a href="javascript:void(0)">featured product</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="sub-footer black-subfooter">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p>
                    <p><i class="fa fa-copyright" aria-hidden="true"></i>
						Copyright  {{ today | date:'y'}} Aquila Tech Solutions | All Rights Reserved.</p>
                    </div>
                </div>
                <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/visa.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/mastercard.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/paypal.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/american-express.png" alt=""></a>
                            </li>
                            <li>
                                <a href="javascript:void(0)"><img src="assets/images/icon/discover.png" alt=""></a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->


<script>
    // ----------------- Variables

    wrapper = $(".tabs");
    tabs = wrapper.find(".tab");
    tabToggle = wrapper.find(".tab-toggle");

    // ----------------- Functions

    function openTab() {
      var content = $(this).parent().next(".content"),
        activeItems = wrapper.find(".active");

      if (!$(this).hasClass('active')) {
        $(this).add(content).add(activeItems).toggleClass('active');
        wrapper.css('min-height', content.outerHeight());
      }
    };

    // ----------------- Interactions

    tabToggle.on('click', openTab);

    // ----------------- Constructor functions

    $(window).load(function () {
      tabToggle.first().trigger('click');
    });

  </script>

  <script>
    $('.home-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,

          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,

          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,

          }

        }
      ]
    });


    $('.our-partners').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 150,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,

          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 2,

          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,

          }

        }
      ]
    });

  </script>
